import { useStyles } from '@conte-ltd/components-theme'
import { css, keyframes } from '@emotion/react'
import React from 'react'
import type { Theme } from '../styles/theme'

const spinner_anime = keyframes`
	100% { 
    transform: rotate(360deg); 
  }
`

export function Spinner() {
  const { display, size, border, borderRadius } = useStyles<Theme>()
  return (
    <span
      css={[
        display.inlineBlock,
        size.em[15],
        border.all.medium,
        borderRadius.all.full,
        css`
          border-color: rgba(0, 0, 0, 0.2);
          border-top-color: black;
          border-radius: 50%;
          animation: ${spinner_anime} 0.8s infinite linear;
        `,
      ]}
    />
  )
}
